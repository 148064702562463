import React from 'react';
import styles from './index.css';
import PropTypes from 'prop-types';
import Chip from 'Chip';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NoSSR from 'react-no-ssr';

/**
 * for this page
 */
import { Caption } from 'ThreeTerminal_mobile/caption';
import { SlidesTabs } from 'ThreeTerminal_mobile/tabs';
import { HomeFooter, Copyright } from 'ThreeTerminal_mobile/copyright';
import Content from './content';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { navigation, videoNavigation, footerInfo, slideAd, streamAd, slideData } = this.props.content;
        const captionTitle = {
            title: '凤凰视频',
            url: '//tv.ifeng.com',
        };
        const contentAd = {
            slideAd,
            streamAd,
        };

        // console.log(this.props.content);

        return (
            <section className={styles.layout}>
                <Caption title={captionTitle} content={navigation} />
                <Chip
                    id="50182"
                    type="struct"
                    title="视频导航"
                    groupName="header"
                    translate="handleHomeNavInfo"
                    content={videoNavigation}>
                    <SlidesTabs content={videoNavigation} currentPath="1-" />
                </Chip>
                <Content ad={contentAd} content={{ slideData }} />
                <div className={styles.homeFooterWrapper}>
                    <HomeFooter content={footerInfo} />
                </div>
                <Copyright />
                <ChipEdit transform={transform} />
            </section>
        );
    }
}

export default errorBoundary(Layout);
