import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import Layout from './layout';
import { formatImage } from '@ifeng-fe/public_method';

/* eslint-disable */
allData.slideData.forEach(item => {
    item.src = item.thumbnail ? formatImage(item.thumbnail, 345, 175) : '';
});
ReactDOM.hydrate(<Layout content={allData} />, document.getElementById('root'));
/* eslint-enable */
