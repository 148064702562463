import React from 'react';
import styles from './index.css';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NoSSR from 'react-no-ssr';
import { getImage } from '@ifeng-fe/container_channel/common/client/spring/mobile/utils/cutImg';
import Chip from 'Chip';

/**
 * for this page
 */

import Loadable from 'react-loadable';
import LazyLoad from '@ifeng-fe/ui_pc_lazyLoad';

import Slides from './slides';
// import VideoStream from './videoStreams';
import { getVideoHomeList, getVideoHomeStream, getTvHomeStream, getVideoPlayNum } from '../../../services/api';

import CullUpItem from './callUpItem';

const VideoStream = Loadable({
    loader: () => import('./videoStreams'),
    loading() {
        return <div />;
    },
});

const formatListData = (data, type) => {
    return data.map(item => ({
        posterUrl: item.posterUrl,
        title: item.title,
        source: item.columnName,
        newsTime: item.newsTime,
        duration: item.duration,
        guid: item.guid,
        base62Id: item.base62Id,
        url: `//i.ifeng.com/c/${item.base62Id}`,
        type: item.type,
        newstype: item.type,
        thumbnail: item.posterUrl,
        commentUrl: item.guid,
        itemId: item.itemId,
        newStyle: 'titleimg',
    }));
};

class Content extends React.PureComponent {
    static propTypes = {
        ad: PropTypes.object,
        content: PropTypes.object,
    };

    state = {
        pageNum: 20,
        page: 1,
        data: new Array(),
        thisItemId: 19,
    };

    componentDidMount() {
        this.initList();
    }

    // 初始化数据
    initList() {
        const { page, pageNum } = this.state;

        this.asyncGetVideoHomeList(page, pageNum);
    }

    stopLoad = false;
    // 请求首屏数据
    asyncGetVideoHomeList = async (page, pageNum) => {
        try {
            const res = await getVideoHomeList(pageNum);
            const headerData = [];
            let bodyListData = [];

            if (res && res.header) {
                res.header = res.header.filter(item => {
                    return item.memberType === 'video';
                });
                res.header.forEach((item, index) => {
                    const _item = {};

                    if (item.memberItem) {
                        _item.title = item.title;
                        _item.guid = item.infoId || item.memberItem.guid;
                        _item.src = getImage(item.memberItem.image, 345, 175);
                        _item.url = `//i.ifeng.com/c/${
                            item.memberItem.ucmsId ? item.memberItem.ucmsId : item.memberItem.guid
                        }`;
                        headerData.push(_item);
                    }
                });
            }
            res.header = headerData;

            const bodyList = await getTvHomeStream(page, pageNum);

            if (bodyList && bodyList.code === 0) {
                const formatData = formatListData(bodyList.data.data, 0);

                bodyListData = [...formatData];

                const cullUpItemGuids = [bodyListData[3].guid, bodyListData[4].guid];

                const cullUpItemPlayCounts = await getVideoPlayNum(cullUpItemGuids);

                bodyListData[3].insertJSX = (
                    <NoSSR>
                        <CullUpItem
                            index={3}
                            item={{ ...bodyListData[3], playTime: cullUpItemPlayCounts[bodyListData[3].guid] }}
                            url={bodyListData[3].url}
                        />
                    </NoSSR>
                );
                bodyListData[4].insertJSX = (
                    <NoSSR>
                        <CullUpItem
                            index={4}
                            item={{ ...bodyListData[4], playTime: cullUpItemPlayCounts[bodyListData[4].guid] }}
                            url={bodyListData[4].url}
                        />
                    </NoSSR>
                );

                res.bodyList = bodyListData;

                this.setState({
                    data: res,
                    thisItemId: bodyListData[bodyListData.length - 1].itemId,
                });
            }
        } catch (e) {
            console.error(e);
        }
    };
    // 无限加载信息流
    asyncGetVideoHomeStream = async pageSize => {
        try {
            const { thisItemId } = this.state;
            const res = await getVideoHomeStream(pageSize, thisItemId, 0);

            if (res.hasError) {
                this.stopLoad = true;
            } else {
                this.stopLoad = false;
            }

            return res;
        } catch (e) {
            console.error(e);
        }
    };

    handleLoadMore = async () => {
        try {
            const { data, page, pageNum } = this.state;

            const newPage = page + 1;

            let returnData = new Object();
            const resData = await getTvHomeStream(page + 1, pageNum);

            // console.log(resData);

            const formatData = formatListData(resData.data.data);

            if (this.stopLoad) {
                console.log(this.stopLoad);
                returnData = {
                    content: formatData,
                    isEnd: true,
                };
            }

            if (formatData.length) {
                returnData = { content: formatData, isEnd: false };
                this.setState({ thisItemId: formatData[formatData.length - 1].itemId, page: newPage });
            } else {
                returnData = { content: data.bodyList, isEnd: false };
                this.setState({ thisItemId: data.bodyList[data.bodyList.length - 1].itemId, page: newPage });
            }

            return returnData;
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        // console.log(this.props.content);
        const { data } = this.state;
        const { slideAd, streamAd } = this.props.ad;
        const { slideData } = this.props.content;

        return (
            <section className={styles.content}>
                <section>
                    {data.header ? (
                        <NoSSR>
                            <Chip
                                id="75167"
                                type="recommend"
                                title="视频首页-焦点图"
                                groupName="正文"
                                translate="handleVideoSlideData"
                                content={slideData}>
                                <Slides ad={slideAd} />
                            </Chip>
                            {/* <Slides content={data.header} ad={slideAd} /> */}
                        </NoSSR>
                    ) : null}
                </section>
                {data.bodyList ? (
                    <LazyLoad>
                        <VideoStream content={data.bodyList} handleLoadMore={this.handleLoadMore} ad={streamAd} />
                    </LazyLoad>
                ) : null}
            </section>
        );
    }
}

export default errorBoundary(Content);
