import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import { getImage } from '@ifeng-fe/container_channel/common/client/spring/mobile/utils/cutImg';
import { handleCallNewsApp, h5PageCount } from '@ifeng-fe/three_terminal/es/utils';
import { formatPlayNum } from '@ifeng-fe/three_terminal/es/mobile/informationFlow/informationFlow/utils/playNumUnit';
import { formatVideoDuration } from '@ifeng-fe/three_terminal/es/mobile/informationFlow/informationFlow/utils/formatVideoDuration';
import { getImageUrl } from '@ifeng-fe/three_terminal/es/mobile/informationFlow/informationFlow/utils/image';
import { callVideoAPP } from '@ifeng-fe/container_channel/common/client/spring/mobile/utils/callVideoApp';

// 左文右图
class TitleImg extends PureComponent {
    static propTypes = {
        item: PropTypes.object,
        url: PropTypes.string,
        isClickListItem: PropTypes.bool,
    };

    static defaultProps = {
        isDisplaySource: true,
        isCount: false,
        isNewsTime: false,
        isFormatTime: false,
        isClickListItem: false,
        isLazyImage: true,
    };

    state = {
        topicCount: 0,
        topicUrl: '',
        surveyCount: 0,
        slideNum: 0,
    };

    ref = React.createRef();

    handleClick = (url, id, newstype, kind, downloads, clickCountConfig) => {
        // console.log(url, id, newstype, kind, downloads, clickCountConfig);
        // eslint-disable-next-line no-debugger
        // debugger;

        this.feedClickCountFn(clickCountConfig);

        callVideoAPP({
            scheme: {
                type: 'video',
                guid: id,
                id,
                channel: 'h5',
            },
        });
    };

    feedClickCountFn = config => {
        if (config) {
            h5PageCount([config]);
        }
    };

    render() {
        const { topicUrl } = this.state;
        let { url } = this.props;

        url = topicUrl || url;

        const { item, isClickListItem } = this.props;
        const {
            title,
            source,
            guid,
            type,
            newstype,
            clickCount,
            kind,
            downloads,
            thumbnail,
            thumbnails,
            duration,
        } = item;
        const videoplay = item.playTime;
        const clickCountConfig = clickCount ? clickCount : '';

        let image = thumbnail && thumbnail !== '' ? thumbnail : thumbnails;

        // thumbnails
        if (image && typeof image === 'object' && 'image' in image) {
            image = getImageUrl(image.image[0]);
        }

        const src = image ? getImage(image, 226, 150) : '';
        const id = guid;

        return (
            <div
                className={styles.titleImg}
                onClick={
                    isClickListItem
                        ? this.handleClick.bind(this, url, id, 'phvideo', kind, clickCountConfig, downloads)
                        : undefined
                }>
                <div className={styles.link}>
                    <a
                        className={styles.title}
                        onClick={this.handleClick.bind(this, url, id, 'phvideo', kind, downloads, clickCountConfig)}>
                        {title}
                    </a>

                    <div className={styles.info}>
                        <div>
                            <span style={{ color: '#f54343' }} className={styles.stickMark}>
                                推广
                            </span>
                            <span className={styles.source}>{source}</span>
                            {videoplay && <span className={styles.count}>{`${formatPlayNum(videoplay)}次播放`}</span>}
                        </div>
                    </div>
                </div>
                <div className={styles.imgBox}>
                    <a
                        className={styles.img}
                        onClick={this.handleClick.bind(this, url, id, 'phvideo', kind, downloads, clickCountConfig)}>
                        <img src={src} ref={this.ref} />
                        {type === 'video' || type === 'miniVideo' ? (
                            <Fragment>
                                <span className={styles.playIcon} />
                                {duration && (
                                    <span className={styles.videoIcon}>
                                        <span />
                                        <span>{formatVideoDuration(Number(duration))}</span>
                                    </span>
                                )}
                            </Fragment>
                        ) : (
                            ''
                        )}
                    </a>
                </div>
            </div>
        );
    }
}

export default TitleImg;
