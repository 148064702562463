import React from 'react';
import Ad from '@ifeng-fe/ui_pc_ad';
import styles from './index.css';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { IndexSlides } from 'ThreeTerminal_mobile/slides';

/**
 * for this page
 */

class Slides extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
        ad: PropTypes.object,
    };

    render() {
        const { content, ad } = this.props;

        return (
            <div>
                <IndexSlides content={content} ad={ad} /* handleCallUpApp={{ type: 'news', index: 2 }} */ />
            </div>
        );
    }
}

export default errorBoundary(Slides);
